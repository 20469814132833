//HistoryList.js

import History from "./History";
import Exit from "./Exit";
// import { useState } from "react";

export default function HistoryList({ history, setHistory, setMessages, exit }) {
  const handleDelete = (index) => {
    // Remove the item from the history list based on its index
    const updatedHistory = history.filter((_, i) => i !== index);
    setHistory(updatedHistory);
  };

  return (
    <div className="Column">
      <h3 className="Title">History</h3>
      <div className="Content">
        {history.map((el, i) => {
          // console.log("History Item:", el);

          return (
            <History
              key={i}
              question={el.question}
              questionId={el.questionId}
              onClick={() =>
                setMessages([
                  {
                    role: "user",
                    content: el.question,
                    questionId: el.questionId,
                  },
                  {
                    role: "assistant",
                    content: el.answer,
                    questionId: el.questionId,
                    responseId: el.responseId,
                  },
                ])
              }
              onDelete={() => handleDelete(i)} // Pass the delete handler
            />
          );
        })}
      </div>
      <Exit onClick={exit} />
    </div>
  );
}
