import styles from "./History.module.css";

export default function History({ question, onClick, onDelete }) {
  return (
    <div className={styles.wrapper}>
      <p onClick={onClick}>
        {question.length > 15 ? `${question.substring(0, 15)}...` : question}
      </p>
      <button className={styles.deleteButton} onClick={onDelete}>
        x
      </button>
    </div>
  );
}
