//UserMessage.js

import user from "../icons/user.png";
import styles from "./UserMessage.module.css";

export default function UserMessage({ content, questionId }) {
  return (
    <div className={styles.wrapper}>
      <div>
        <img src={user} className={styles.avatar} alt="user avatar" />
      </div>
      <div>
        <p>{content}</p>
          <p>Question ID: {questionId ? questionId: "Waiting for response..."}</p> {/* Display question_id */}
      </div>
    </div>
  );
}