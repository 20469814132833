//Message.js

import BotMessage from "./BotMessage";
import UserMessage from "./UserMessage";

export default function Message(props) {
  const { role } = props;
  if (role === "assistant") {
    return <BotMessage {...props} />;
  } else {
    return <UserMessage {...props} />;
  }
}