// setupInterceptors.ts
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IAM_BE_ENDPOINTS } from './endpoints';
import { iamAxiosInstance } from './axiosInstance';
import { LoginResponse } from './interfaces';

interface ExtendedRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const getAuthData = (): LoginResponse | null => {
  const authCookie = document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === 'auth' ? decodeURIComponent(parts[1]) : r;
  }, '');

  return authCookie ? JSON.parse(authCookie) : null;
};

const setupInterceptors = (
  refresh: () => Promise<void>,
  logout: () => void,
  instance: AxiosInstance,
) => {
  let originalConfig;
  const controller = new AbortController();

  // Add a request interceptor
  iamAxiosInstance.interceptors.request.use(
    (config: ExtendedRequestConfig) => {
      // Get accessToken from the storage
      const parsed = getAuthData();
      // Do something before request is sent
      if (parsed?.accessToken && config.url !== IAM_BE_ENDPOINTS.REFRESH_TOKEN) {
        // Set access token to every request if there is one except for refresh
        config.headers.Authorization = `Bearer ${parsed.accessToken}`;
      }
      if (parsed?.refreshToken && config.url === IAM_BE_ENDPOINTS.REFRESH_TOKEN) {
        // Set refresh token for refresh
        config.headers.Authorization = `Bearer ${parsed.refreshToken}`;
      }
      if (config._retry) {
        // Attach a signal to every request, so we can abort if we need
        config.signal = controller.signal;
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  const MAX_RETRY_ATTEMPTS = 3;
  let retryAttempt = 0;

  iamAxiosInstance.interceptors.response.use(
    (response) => {
      // Reset the retry count on a successful response
      retryAttempt = 0;
      return response;
    },
    async (error) => {
      originalConfig = error?.config;
      if (error?.response) {
        // If token expired and retry limit has not been exceeded
        if ((error.response.status === 401 || error.response.status === 403) && !originalConfig._retry) {
          if (retryAttempt < MAX_RETRY_ATTEMPTS) {
            retryAttempt++;
            originalConfig._retry = true;
            try {
              await refresh();
              return iamAxiosInstance(originalConfig);
            } catch (_error: unknown) {
              // Trigger logout if refresh fails
              logout();
              return Promise.reject(_error);
            }
          } else {
            // Exceeded retry limit, trigger logout
            logout();
            return Promise.reject(error);
          }
        }
      }
      if (originalConfig?.url === IAM_BE_ENDPOINTS.REFRESH_TOKEN) {
        controller.abort();
        logout();
      }
      if (axios.isCancel(error)) {
        return null;
      }
      return Promise.reject(error);
    }
  );

  // Add a request interceptor
  instance.interceptors.request.use(
    (config: ExtendedRequestConfig) => {
      // Get accessToken from the storage
      const parsed = getAuthData();
      // Do something before request is sent
      if (parsed?.accessToken) {
        // Set access token to every request if there is one except for refresh
        config.headers.Authorization = `Bearer ${parsed.accessToken}`;
      }

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      originalConfig = error?.config;
      if (error?.response) {
        // Access Token was expired
        if (
          (error.response.status === 401 || error.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            await refresh();
            return instance(originalConfig);
          } catch (_error: unknown) {
            return Promise.reject(_error);
          }
        }
      }
      if (axios.isCancel(error)) {
        return null;
      }
      return Promise.reject(error);
    },
  );
};

export default setupInterceptors;
