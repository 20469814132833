// Chat.js

import Message from "./Message";
import Input from "./Input";
import styles from "./Chat.module.css"; // New styles for titleWrapper

export default function Chat({ username, input, setInput, messages, handleSubmit, exit }) {
  return (
    <div className="Column">
      <div className={styles.titleWrapper}>
        <p className={styles.username}>
          {username ? `You arrrr ${username}!` : "Loading..."}
        </p>
        <button className={styles.logoutBtn} onClick={exit}>
          Logout
        </button>
      </div>
      <div className="Content">
        {messages.map((message, index) => (
          <Message key={message.id || index} {...message} />
        ))}
      </div>
      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onClick={input ? handleSubmit : undefined}
      />
    </div>
  );
}
