import { iamAxiosInstance, axiosInstance} from './axiosInstance';
import { IAM_BE_ENDPOINTS, ENDPOINTS } from './endpoints';
import {
  LoginResponse,WhoAmIResponse,
  Test,
  genericResponse,genericParams,
  SpellWorldParams,SpellWordResponse,
  HistoryResponse,
  EvaluationParams,EvaluationResponse,
  PreviousEvaluationParams,PreviousEvaluationResponse,
  QueryParams,QueryResponse
} from './interfaces';

class Client {
  logout = async (): Promise<boolean> => {
    const response = await iamAxiosInstance.post(IAM_BE_ENDPOINTS.LOGOUT);
    return response.data;
  };

  refreshToken = async (): Promise<LoginResponse> => {
    const response = await iamAxiosInstance.post(IAM_BE_ENDPOINTS.REFRESH_TOKEN);
    return response.data;
  };

  test = async (): Promise<Test> => {
    const response = await axiosInstance.get(ENDPOINTS.TEST);
    return response.data;
  };

  testToken = async (): Promise<Test> => {
    const response = await axiosInstance.get(ENDPOINTS.TEST_TOKEN);
    return response.data;
  };

  genericPost = async (params: genericParams): Promise<genericResponse> => {
    const response = await axiosInstance.post(`${ENDPOINTS.GENERIC_ENDPOINT}`, params);
    return response.data;
  };

  whoAmI = async (): Promise<WhoAmIResponse> => {
    const response = await axiosInstance.get(`${ENDPOINTS.WHOAMI_ENDPOINT}`);
    return response.data;
  }

  postSpellWord = async (params: SpellWorldParams): Promise<SpellWordResponse> => {
    const response = await axiosInstance.post(`${ENDPOINTS.SPELL_WORD}`, params);
    return response.data;
  };

  postQuery = async (params: QueryParams): Promise<QueryResponse> => {
      const response = await axiosInstance.post(`${ENDPOINTS.QUERY}`, params);
      // Log the raw Axios response
      console.log('Raw Axios response:', response);
      return response.data;
  }

  getResponse = async (question_id: number): Promise<QueryResponse> => {
      const response = await axiosInstance.get(`${ENDPOINTS.QUERY_RESULT}/${question_id}`);
      return response.data;
  }

  getHistory = async (): Promise<HistoryResponse> => {
      const response = await axiosInstance.get(`${ENDPOINTS.HISTORY}`);
      return response.data;
  };

  postEvaluation = async (params: EvaluationParams): Promise<EvaluationResponse> => {
      const response = await axiosInstance.post(`${ENDPOINTS.EVALUATE}`, params);
      return response.data;
  };

  getPreviousEvaluation = async (response_id: number): Promise<PreviousEvaluationResponse> => {
        const response = await axiosInstance.get(`${ENDPOINTS.PREVIOUS_EVALUATION}/${response_id}`);
        return response.data;
  }

}


export default new Client();
