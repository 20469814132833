//App.js
import { useState, useEffect, useCallback } from "react";
import useChat from "./hooks/useChat";
import Chat from "./components/Chat";
import HistoryList from "./components/HistoryList";

import client from "./client/client";


import "./App.css";

export default function App() {
  const { input, setInput, messages, setMessages,
      handleSubmit, exit, history, setHistory, fetchHistory } = useChat();
  const [username, setUsername] = useState(""); // State to store the username

  // Fetch user info from the client on component mount
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await client.whoAmI();
        setUsername(response.username); // Update the username state
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="App">
      <Chat
        username={username}
        input={input}
        setInput={setInput}
        messages={messages}
        handleSubmit={handleSubmit}
        exit={exit}
      />
      <HistoryList
        history={history}
        setMessages={setMessages}
        setHistory={setHistory}
        exit={exit}
      />
    </div>
  );
}