//AuthGuard.tsx
import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/Auth/AuthContext';
import { PUBLIC_PATHS, EXTERNAL_URLS } from '../resources/constants';

export enum AuthProviderType {
  LDAP = 'LDAP',
  SAML = 'SAML',
}

export default function AuthGuard({ children }) {
  const [authorized, setAuthorized] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const currentPath = window.location.pathname; // Use window.location.pathname instead of Next.js' router
    if (!user && !PUBLIC_PATHS.includes(currentPath)) {
      setAuthorized(false);
      const loginUrl = `${EXTERNAL_URLS.login}/login?redirectTo=${window.location.href}`;

      switch (process.env.REACT_APP_AUTH_PROVIDER) {
        case AuthProviderType.SAML:
          window.location.href = EXTERNAL_URLS.samlLogout;
          break;
        case AuthProviderType.LDAP:
        default:
          window.location.href = loginUrl; // Use window.location.href for redirection
      }
    } else {
      setAuthorized(true);
    }
  }, [user]);

  return authorized ? children : null;
}
