import axios, { AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import qs from 'qs';
import { getCookie } from '../utils/cookie';
import { BE_BASE_URL, IAM_BE_BASE_URL } from './endpoints';

const defaultAxiosRequestConfig = {
  baseURL: BE_BASE_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': getCookie('NEXT_LOCALE') || 'hu',
  },
  // Helps to create an array in the query string, for backend consume
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
};
export const createAxiosInstance = (axiosRequestConfig?: AxiosRequestConfig) =>
  applyCaseMiddleware(axios.create({ ...defaultAxiosRequestConfig, ...axiosRequestConfig }));

export const axiosInstance = createAxiosInstance();
export const increasedTimeoutAxiosInstance = createAxiosInstance({ timeout: 600000 });

export const iamAxiosInstance = createAxiosInstance({ baseURL: IAM_BE_BASE_URL });
