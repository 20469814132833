import { useState, useEffect } from "react";
import styles from "./EvaluationModal.module.css";
import client from "../client/client";

export default function EvaluationModal({ show, onClose, responseId }) { // Use responseId here
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const characterLimit = 200;

  // Fetch previous evaluation when the modal opens
  useEffect(() => {
    if (show && responseId) { // Use responseId here
      const fetchEvaluation = async () => {
        setLoading(true);
        try {
          // Fetch previous evaluation using the client method
          const data = await client.getPreviousEvaluation(responseId); // Use responseId here

          if (data.evaluationId) {
            setRating(data.rating || 0);
            setComment(data.comment || "");
          } else {
            // No previous evaluation; reset state
            setRating(0);
            setComment("");
          }
        } catch (error) {
          console.error("Error fetching previous evaluation:", error);
          setErrorMessage("Failed to load previous evaluation.");
        } finally {
          setLoading(false);
        }
      };

      fetchEvaluation();
    }
  }, [show, responseId]); // Use responseId here

  const submitFeedback = async () => {
    try {
      const params = {
        responseId, // Use responseId here
        rating,
        comment,
      };
      const data = await client.postEvaluation(params);
      console.log("Evaluation submitted:", data);
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error submitting evaluation:", error);
      setErrorMessage("Failed to submit feedback. Please try again.");
    }
  };

  const handleClose = () => {
    onClose();
    setRating(0);
    setComment("");
    setErrorMessage("");
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>Rate the Response</h3>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div>
              {/* Use radio buttons for rating */}
              <div>
                <label>
                  <input
                    type="radio"
                    name="rating"
                    value="0"
                    checked={rating === 0}
                    onChange={() => setRating(0)}
                  />
                  No Rating
                </label>
                {[1, 2, 3, 4, 5].map((star) => (
                  <label key={star}>
                    <input
                      type="radio"
                      name="rating"
                      value={star}
                      checked={rating === star}
                      onChange={() => setRating(star)}
                    />
                    {star} Star{star > 1 ? "s" : ""}
                  </label>
                ))}
              </div>
            </div>

            <textarea
              placeholder="Add a comment"
              value={comment}
              className={styles.textarea}
              onChange={(e) => setComment(e.target.value)}
              maxLength={characterLimit}
            />
            <p>
              {comment.length}/{characterLimit} characters
            </p>

            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <button onClick={submitFeedback}>Submit</button>
            <button onClick={handleClose}>Close</button>
          </>
        )}
      </div>
    </div>
  );
}
