export const URLS = {
  root: '/',
  notFound: '/404',
};

export const EXTERNAL_URLS = {
  login: process.env.REACT_APP_IAM_FE_BASE_URL,
  samlLogout: process.env.REACT_APP_IAM_BE_BASE_URL + '/saml/logout',
  site: 'https://mome.hu',
};

export const PUBLIC_PATHS = [];

export const IDLE_TIMEOUT = 1000 * 60 * 15; // 15 perc milliszekundumokban
export const PROMPT_TIMEOUT = 1000 * 60; // 1 perc milliszekundumokban
