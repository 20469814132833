export const BE_BASE_URL = process.env.REACT_APP_BE_BASE_URL;
export const IAM_BE_BASE_URL = process.env.REACT_APP_IAM_BE_BASE_URL;

export const IAM_BE_ENDPOINTS = {
  REFRESH_TOKEN: '/auth/refresh',
  LOGOUT: '/auth/logout',
};

export const ENDPOINTS = {
  LOGIN: '/token',
  TEST: '/test',
  TEST_TOKEN: '/test_token',
  WHOAMI_ENDPOINT: '/whoami',
  ASK: '/ask',
  EVALUATE: '/evaluate',
  PREVIOUS_EVALUATION: '/previous_evaluation',
  USER_HISTORY: '/user_history',
  ANSWERS: '/answers',
  GENERIC_ENDPOINT: '/generic_endpoint',
  SPELL_WORD: '/spell_word',
  QUERY: '/query',
  QUERY_RESULT: '/query_result',
  HISTORY: '/history',
};
