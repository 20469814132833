// Exit.js

import styles from "./Exit.module.css";

export default function Exit({ onClick }) {
  return (
    <button className={styles.wrapper} onClick={onClick}>
      Exit
    </button>
  );
}
