/* eslint-disable no-console */
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useEventListener } from '../index';
import { CookieOptions } from '../../client/interfaces';

declare global {
  interface WindowEventMap {
    cookie: CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

function stringifyOptions(options) {
  return Object.keys(options).reduce((acc, key) => {
    if (key === 'days') {
      return acc;
    } else {
      if (options[key] === false) {
        return acc;
      } else if (options[key] === true) {
        return `${acc}; ${key}`;
      } else {
        return `${acc}; ${key}=${options[key]}`;
      }
    }
  }, '');
}

export const defaultCookieOptions: CookieOptions = {
  domain: process.env.REACT_APP_BASE_DOMAIN,
};

function useCookie<T>(name: string, initialValue: T, options: CookieOptions): [T, SetValue<T>] {
  // Get from cookie then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '');

      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading cookie name “${name}”:`, error);
      return initialValue;
    }
  }, [initialValue, name]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValueRef = useRef<SetValue<T>>();

  setValueRef.current = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window == 'undefined') {
      console.warn(`Tried setting cookie name “${name}” even though environment is not a client`);
    }

    try {
      const optionsWithDefaults = {
        days: 7,
        path: '/',
        ...options,
      };

      let expires = new Date(Date.now() + optionsWithDefaults.days * 864e5).toUTCString();

      let stringValue: string;

      if (!value) {
        // Remove cookie by setting the expiration date in the past
        stringValue = '';
        expires = new Date(Date.parse('1970')).toUTCString();
      } else if ('object' === typeof value) {
        stringValue = JSON.stringify(value);
      } else {
        stringValue = value as string;
      }

      // Save to cookie
      document.cookie =
        name +
        '=' +
        encodeURIComponent(stringValue) +
        '; expires=' +
        expires +
        stringifyOptions(optionsWithDefaults);
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useCookie hook are notified
      window.dispatchEvent(new Event('cookie'));
    } catch (error) {
      console.warn(`Error setting cookie name “${name}”:`, error);
    }
  };

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to cookie.
  const setValue: SetValue<T> = useCallback((value) => setValueRef.current?.(value), []);

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueToCookie
  // See: useCookie()
  useEventListener('cookie', handleStorageChange);

  return [storedValue, setValue];
}

export default useCookie;

function parseJSON<T>(value: string | null): T | undefined {
  try {
    const needsParsing = value.startsWith('{');

    if (needsParsing) {
      return JSON.parse(value);
    }

    if (value === 'undefined') {
      return undefined;
    }

    return value as T;
  } catch {
    console.error('parsing error on', { value });
    return undefined;
  }
}
