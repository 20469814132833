import styles from "./Input.module.css";

export default function Input({ value, onChange, onClick, }) {
    const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
    };
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.text}
        placeholder="Your prompt here..."
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      <button className={styles.btn} onClick={onClick}>
        Go
      </button>
    </div>
  );
}
