// BotMessage.js
import { useState, useEffect } from "react";
import bot from "../icons/bot.png";
import styles from "./BotMessage.module.css";
import EvaluationModal from "./EvaluationModal";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import client from "../client/client";
import showdown from 'showdown';

export default function BotMessage({ content, questionId, responseId, sources, citations }) {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [formattedContent, setFormattedContent] = useState(content); // Default to the content string

  const handleFeedback = () => {
    setShowFeedbackModal(true);
  };

  // Function to reindex and replace citations in the content
  const formatContentWithCitations = async (text, citations, sources) => {
    const converter = new showdown.Converter();
    converter.setOption('simpleLineBreaks', true);
    text = converter.makeHtml(text)

    if (!citations || !sources) {
      if (!questionId) {
        console.error("questionId is undefined");
        return text; // Return the plain text
      }

      try {
        const response = await client.getResponse(questionId);
        citations = response.citations;
        sources = response.context;
      } catch (error) {
        console.error("Error fetching response:", error);
        return text; // Return the plain text in case of error
      }
    }

    // Create a map of original citation indices to new ones
    const citationMap = {};
    citations.forEach((citation, index) => {
      citationMap[citation] = index + 1; // Reindex to 1, 2, 3, ...
    });

    // Replace citations in the text with reindexed links
    return text.replace(/\[(\d+(?:,\d+)*)\]/g, (match, group) => {
      const indices = group.split(",").map(Number);
      const links = indices.map((originalIndex) => {
        const newIndex = citationMap[originalIndex];
        const source = sources[originalIndex];
        if (source && newIndex) {
          return `<a href="${source.source}" target="_blank" title="${source.content}">[${newIndex}]</a>`;
        }
        return `[${newIndex}]`;
      });
      return links.join(", ");
    });
  };

  // Fetch and format content when component mounts or dependencies change
  useEffect(() => {
    const fetchFormattedContent = async () => {
      const result = await formatContentWithCitations(content, citations, sources);
      setFormattedContent(result); // Ensure `result` is a string
    };

    fetchFormattedContent();
  }, [content, citations, sources, questionId]);

  return (
    <div className={showFeedbackModal ? styles.wrapperBlur : styles.wrapper}>
      <div>
        <img src={bot} className={styles.avatar} alt="bot avatar" />
      </div>
      <div>
        {/* Ensure `formattedContent` is a string */}
        {formattedContent ? (
          <div style={{ listStylePosition: "inside" }} dangerouslySetInnerHTML={{ __html: formattedContent }}>
          </div>
        ) : (
          <p>Loading...</p>
        )}

        <button onClick={handleFeedback}>Rate me pls</button>
      </div>
      <EvaluationModal
        show={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        responseId={responseId}
      />
    </div>
  );
}