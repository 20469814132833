//useChat.js

import { useState, useEffect } from "react";
import client from "../client/client";
import { useAuth } from "../contexts/Auth/AuthContext"; // Import useAuth

export default function useChat() {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const { logout } = useAuth();

  const fetchHistory = async () => {
    try {
      const response = await client.getHistory();
      setHistory(response.history);
      console.log("History fetched successfully");
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  // useEffect to fetch history when the hook is initialized
  useEffect(() => {
    fetchHistory();
  }, []);

const sendMessage = async (promptContent) => {
  // Immediately add the user's message to the state
  const userMessage = {
    role: "user",
    content: promptContent,
    questionId: null, // Temporarily set to null
  };

  // Set the messages state to include only the user message
  setMessages([userMessage]);

  try {
    // Send the query to the API
    const data = await client.postQuery({ query: promptContent });

    // Log the response data
    console.log('Response data:',data);


    // Log the data properties
    console.log('Data keys:', Object.keys(data));



    // Check if data contains the questionId
    console.log("Data from API:", data);

    // Create a new user message with the correct `questionId`
    const updatedUserMessage = {
      ...userMessage,
      questionId: data.questionId,
    };

    // Create the assistant's response message
    const assistantMessage = {
      role: "assistant",
      content: data.answer,
      questionId: data.questionId,
      responseId: data.responseId,
      sources: data.context,
      citations: data.citations,
    };

    // Update the messages state with the updated user message and assistant message
    setMessages([updatedUserMessage, assistantMessage]);

    // Update history
    setHistory((prevHistory) => [
      ...prevHistory,
      {
        question: promptContent,
        answer: data.answer,
        questionId: data.questionId,
        responseId: data.responseId,
        sources: data.context,
        citations: data.citations,
      },
    ]);
  } catch (error) {
    console.error("Error:", error);
    setMessages([
      userMessage,
      {
        role: "assistant",
        content: "Sorry, I'm not here! There was some kind of error!",
      },
    ]);
  }
};

  const handleSubmit = async () => {
    const promptContent = input;
    await sendMessage(promptContent);
    setInput("");
  };

  const exit = async () => {
    await logout();
  };

  return {
    input,
    setInput,
    messages,
    setMessages,
    handleSubmit,
    exit,
    history,
    setHistory,
    fetchHistory,
  };
}
