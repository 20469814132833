// AuthContext.tsx
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import client from '../../client/client';
import { User } from '../../client/interfaces';
import setupInterceptors from '../../client/setupInterceptors';
import { useCookie } from '../../hooks';
import { defaultCookieOptions } from '../../hooks/useCookie';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import { EXTERNAL_URLS } from '../../resources/constants';
import { axiosInstance, increasedTimeoutAxiosInstance } from '../../client/axiosInstance';
import { AuthProviderType } from '../../guards/AuthGuard';

type authContextType = {
  user: User;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  error: string;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
  isDelegated: boolean;
};

const authContextDefaultValues: authContextType = {
  user: null,
  isDelegated: false,
  accessToken: '',
  refreshToken: '',
  tokenType: '',
  error: '',
  logout: async () => {},
  refresh: async () => {},
};

export const AuthContext = createContext<authContextType>(authContextDefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: PropsWithChildren) {
  const [auth, setAuth] = useCookie('auth', null, defaultCookieOptions);
  const [, setLastActivity] = useCookie('lastActivity', null, defaultCookieOptions);
  const [user, setUser] = useState<User>(auth?.user || authContextDefaultValues.user);
  const [accessToken, setAccessToken] = useState<string>(
    auth?.accessToken || authContextDefaultValues.accessToken,
  );
  const [isDelegated, setIsDelegated] = useState<boolean>(
    auth?.isDelegated || authContextDefaultValues.isDelegated,
  );
  const [refreshToken, setRefreshToken] = useState<string>(
    auth?.refreshToken || authContextDefaultValues.refreshToken,
  );
  const [tokenType, setTokenType] = useState<string>(
    auth?.tokenType || authContextDefaultValues.tokenType,
  );
  const [error, setError] = useState<string>(authContextDefaultValues.error);
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      if (auth && auth.user) {
        setUser(auth.user);
      }
      setupInterceptors(handleRefresh, handleLogout, axiosInstance);
      setupInterceptors(handleRefresh, handleLogout, increasedTimeoutAxiosInstance);
    }
  }, [isFirstRender]);

  const handleRefresh = async () => {
    try {
      setError('');
      const response = await client.refreshToken();
      setUser(response.user);
      setAccessToken(response.accessToken);
      setRefreshToken(response.refreshToken);
      setTokenType(response.tokenType);
      setAuth({
        user: response.user,
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        tokenType: response.tokenType,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    setError('');
    try {
      const response = await client.logout();
      if (response) {
        setUser(null);
        setAccessToken('');
        setRefreshToken('');
        setTokenType('');
        setAuth(null);
        setIsDelegated(false);
        setLastActivity(null);
        window.sessionStorage.clear();
      }
    } catch (error) {
      setUser(null);
      setAccessToken('');
      setRefreshToken('');
      setTokenType('');
      setAuth(null);
    }

    switch (process.env.REACT_APP_AUTH_PROVIDER) {
      case AuthProviderType.SAML:
        window.location.href = EXTERNAL_URLS.samlLogout;
        break;
      case AuthProviderType.LDAP:
      default:
        window.location.href = `${EXTERNAL_URLS.login}/login?redirectTo=${window.location.href}`;
    }
  };

  const value = {
    user,
    accessToken,
    error,
    refreshToken,
    tokenType,
    isDelegated,
    logout: handleLogout,
    refresh: handleRefresh,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
